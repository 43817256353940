import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import { NavigationGuard, NavigationHookAfter } from 'vue-router';
import { useLogin } from '@/store/modules/login';
import i18n from '@/plugins/i18n';

NProgress.configure({ showSpinner: false });

interface NaviGuard {
  before: NavigationGuard;
  after: NavigationHookAfter;
}

// 设置浏览器title
const setTitle = (title: string, i18nKey: string) => {
  if (i18nKey) {
    title = i18n.global.t(i18nKey);
  }
  if (title) {
    document.title = title as string;
  }
};

const loginGuard: NavigationGuard = (to) => {
  if (!useLogin().token && to.meta.isAuth) {
    return `/login?redirect=${to.fullPath}`;
  }
};

const ProgressGuard: NaviGuard = {
  before: (to) => {
    setTitle(to?.meta?.title as string, to?.meta?.i18nKey as string);
    NProgress.start();
  },
  after: () => {
    NProgress.done();
  },
};

export default {
  before: [ProgressGuard.before, loginGuard],
  after: [ProgressGuard.after],
};
