import { ref } from 'vue';
import { defineStore } from 'pinia';
import { PlanListResponse, ActiveListResponse } from '@/types/mission';
import { PlanList, ActiveList } from '@/apis/mission';
import { UpgradeListResponse } from '@/types/distributor';
import { UpgradeList } from '@/apis/distributor';

export const useMissionStore = defineStore('mission', () => {
  const planList = ref<PlanListResponse['plans']>();
  const activeList = ref<ActiveListResponse['plans']>();
  const upgradeList = ref<UpgradeListResponse['expert']>();

  async function getPlanList() {
    const res = await PlanList({});
    if (res?.code !== 200) return;
    planList.value = res?.data?.plans ?? [];
  }

  const getActiveList = async () => {
    const res = await ActiveList({});
    if (res?.code !== 200) return;
    activeList.value = res.data?.plans;
  };

  async function getUpgradeList() {
    const res = await UpgradeList({});
    if (res?.code !== 200) return;
    upgradeList.value = res?.data?.expert;
  }

  return {
    planList,
    activeList,
    upgradeList,
    getPlanList,
    getActiveList,
    getUpgradeList,
  };
});
