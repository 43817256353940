import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { Account } from '@/types/account';
import { AccountGet } from '@/apis/account';
import { encryptData, decryptData } from '@/plugins/cryptoJs';

export const useAccountStore = defineStore(
  'account',
  () => {
    const UserInfo = ref<any>();
    const userInfo = computed(() =>
      !UserInfo.value
        ? null
        : __APP_MODE__ === 'development'
          ? UserInfo.value
          : (JSON.parse(decryptData(UserInfo.value)) as Account)
    );

    async function getAccount() {
      const res = await AccountGet({});
      if (res?.code === 200) {
        const { data } = res;
        const info = { ...data, setPayPassword: (data.verified_meta & 16) > 0 };
        UserInfo.value = __APP_MODE__ === 'development' ? info : encryptData(JSON.stringify(info));
        return info;
      } else {
        return Promise.reject({});
      }
    }

    function reset() {
      UserInfo.value = null;
    }

    return {
      UserInfo,
      userInfo,
      getAccount,
      reset,
    };
  },
  {
    persist: {
      key: 'Account',
      pick: ['UserInfo'],
    },
  }
);
