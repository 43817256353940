import { createApi } from '.';
import { distributor } from './apiMapping';
import {
  ApplyResponse,
  StatusGetResponse,
  BindParams,
  DetailResponse,
  LinkResponse,
  SearchParams,
  SearchResponse,
  UpgradeListResponse,
  ListParams,
  ContributionListResponse,
  MemberResponse,
  PerformanceResponse,
} from '@/types/distributor';

export const Apply = createApi<unknown, ApplyResponse>(distributor.apply.url);
export const StatusGet = createApi<unknown, StatusGetResponse>(distributor.statusGet.url);
export const Bind = createApi<BindParams, unknown>(distributor.bind.url);
export const Detail = createApi<unknown, DetailResponse>(distributor.detail.url);
export const Link = createApi<unknown, LinkResponse>(distributor.link.url);
export const Search = createApi<SearchParams, SearchResponse>(distributor.search.url);
export const UpgradeList = createApi<unknown, UpgradeListResponse>(distributor.upgradeList.url);
export const ContributionList = createApi<ListParams, ContributionListResponse>(distributor.contributionList.url);
export const MemberList = createApi<ListParams, MemberResponse>(distributor.memberList.url);
export const PerformanceList = createApi<ListParams, PerformanceResponse>(distributor.performanceList.url);
