import { ref } from 'vue';
import { defineStore } from 'pinia';
import Dayjs from 'dayjs';
import { getCurLocale } from '@/config/region';
import { generateUUID } from '@/utils/generate';
import { LocaleIan } from '@/types';
import { useAccountStore } from './account';

export const useSettingsStore = defineStore(
  'settings',
  () => {
    const GlobalImg = ref<string>();
    const pageTitle = ref<Record<string, string>>({});
    const LocaleInfo = ref<LocaleIan>(getCurLocale());
    const DeviceId = ref<string>();
    const UserShowCouponTime = ref<Record<string, number>>({});

    async function setPageTitle(name: string, title: string) {
      pageTitle.value[name] = title;
    }

    async function setGlobalImg(path: string) {
      GlobalImg.value = path;
    }

    async function setLocale(info: LocaleIan) {
      LocaleInfo.value = info;
    }

    function getDeviceId(): string {
      if (!DeviceId.value) {
        DeviceId.value = generateUUID();
      }
      return DeviceId.value;
    }

    function setUserShowCouponTime(userId?: string) {
      UserShowCouponTime.value[userId || useAccountStore()?.userInfo?.user_id] = Dayjs().date();
    }

    function checkUserShowCouponTime(userId?: string): boolean {
      const checkType = !!UserShowCouponTime.value[userId || useAccountStore()?.userInfo?.user_id];
      !checkType && setUserShowCouponTime(userId);
      return checkType;
    }

    return {
      pageTitle,
      GlobalImg,
      LocaleInfo,
      DeviceId,
      UserShowCouponTime,
      setPageTitle,
      setGlobalImg,
      setLocale,
      getDeviceId,
      checkUserShowCouponTime,
    };
  },
  {
    persist: {
      key: 'Settings',
      pick: ['GlobalImg', 'LocaleInfo', 'DeviceId', 'UserShowCouponTime'],
    },
  }
);
