import { LocaleIan } from '@/types';
import { localeFilter, Locale } from './locale';

export const LocaleList: LocaleIan[] = localeFilter(__APP_MODE__ === 'prod' ? ['id', 'us', 'cn'] : '');

// 当前语言环境
const localSet = window?.localStorage?.getItem('Settings') ?? '';
export const getCurLocale = () => ({
  ...(localSet
    ? Locale[JSON.parse(localSet)?.LocaleInfo?.region]
    : Locale[__APP_CURRENCY__.toLocaleLowerCase()] || Locale.id),
});
