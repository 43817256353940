// import 'tailwindcss/tailwind.css'
import { createApp } from 'vue';
// import { ConfigProvider } from 'vant';
// toast/dialog编程式会丢失样式，需要单独引入
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/lib/index.css';
import '@/assets/styles/index.less';
import '@/plugins/amfeFlexible';
import i18n from '@/plugins/i18n';
import { ConfigProvider, setLocale } from '@/plugins/vant';
import router from '@/router';
import pinia from '@/store';
import { setDayDefault } from '@/plugins/day';
import { getAssetUrl, getStaticFullUrl, getI18nName } from '@/utils/tools';
import { formatTime, formatPriceFixed } from '@/utils/format';
import VueComponent from '@/components/VueComponent';
import { getCurLocale } from '@/config/region';
import App from './App.vue';

const currentLocale = getCurLocale();
setDayDefault(currentLocale as any);
setLocale(currentLocale as any);

const app = createApp(App);
// 全局属性
app.config.globalProperties.$currentLocale = currentLocale;
app.config.globalProperties.$getAssetsFile = getAssetUrl;
app.config.globalProperties.$getStaticFullUrl = getStaticFullUrl;
app.config.globalProperties.$formatTime = formatTime;
app.config.globalProperties.$formatPriceFixed = formatPriceFixed;
app.config.globalProperties.$getI18nName = getI18nName;

// app.config.compilerOptions.isCustomElement = (tag: string) => tag === 'amp-ad';

// 使用插件
app.use(i18n).use(pinia).use(router).use(ConfigProvider).use(VueComponent);

// 全局错误处理
app.config.errorHandler = (err, _vm, info) => {
  console.error('未捕获的异常：', err, info);
};

app.mount('#app');
