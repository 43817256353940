import { defineStore } from 'pinia';
import { Create } from '@/apis/orders';
import { CreateParams } from '@/types/orders';

export const useOrdersStore = defineStore('orders', () => {
  async function createOrder(params: CreateParams) {
    return await Create(params);
  }

  return {
    createOrder,
  };
});
