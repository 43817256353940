import { createApi } from '.';
import { payments } from './apiMapping';
import {
  PayParams,
  StripeCreateParams,
  StripeCreateResponse,
  StripeCheckParams,
  InTheBagCreateParams,
  InTheBagCreateResponse,
  InTheBagCheckParams,
  RateGetResponse,
  MonetapayCreateParams,
  MonetapayCreateResponse,
  MonetapayCheckParams,
} from '@/types/payments';

export const Pay = createApi<PayParams, unknown>(payments.pay.url);
export const StripeCreate = createApi<StripeCreateParams, StripeCreateResponse>(payments.stripeCreate.url);
export const StripeCheck = createApi<StripeCheckParams, unknown>(payments.stripeCheck.url);
export const InTheBagCreate = createApi<InTheBagCreateParams, InTheBagCreateResponse>(payments.inTheBagCreate.url);
export const InTheBagCheck = createApi<InTheBagCheckParams, unknown>(payments.inTheBagCheck.url);
export const RateGet = createApi<unknown, RateGetResponse>(payments.rateGet.url);
export const MonetapayCreate = createApi<MonetapayCreateParams, MonetapayCreateResponse>(payments.monetapayCreate.url);
export const MonetapayCheck = createApi<MonetapayCheckParams, unknown>(payments.monetapayCheck.url);
