import { createApi } from '.';
import { orders } from './apiMapping';
import {
  No,
  CreateParams,
  DetailResponse,
  ListParams,
  ListResponse,
  RefundParams,
} from '@/types/orders';

export const Create = createApi<CreateParams, No>(orders.create.url);
export const Detail = createApi<No, DetailResponse>(orders.detail.url);
export const List = createApi<ListParams, ListResponse>(orders.list.url);
export const Cancel = createApi<No, unknown>(orders.cancel.url);
export const Refund = createApi<RefundParams, unknown>(orders.refund.url);
export const RefundCancel = createApi<No, unknown>(orders.refundCancel.url);

