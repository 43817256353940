import { createI18n } from 'vue-i18n';
import { LocaleList, getCurLocale } from '@/config/region';
import langData from './languages.json';


// 当前语言集合
export const getLanguageGroup = () => {
  const _lang: any = {};
  LocaleList?.map((item) => {
    const data = (langData as any)?.[item.language];
    if (data) {
      _lang[item.language] = data;
    }
  });
  return _lang;
};

export const LanguageGroup = getLanguageGroup();

// 创建i18n
const i18n = createI18n({
  locale: getCurLocale().language,
  globalInjection: true,
  legacy: false,
  messages: {
    ...LanguageGroup,
  },
});

// 语言配置
export default i18n;
