<script setup lang="ts">
  interface Props {
    text?: string;
  }
 const props = defineProps<Props>()
</script>

<template>
  <div class="w-full h-full overflow-hidden flex flex-col justify-center items-center py-[32px] px-[16px] text-center leading-[20px]">
    <img src="@/assets/images/common/img_no_data.png" alt="" class="w-[138px]" />
    <div class="text-[16px] text-[#80838D] mt-[24px]">{{ props?.text || $t('Common.NoData') }}</div>
  </div>
</template>

<style scoped lang="less"></style>
