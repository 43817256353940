<script lang="ts" setup>
  import { ref } from 'vue';
  import { getStaticFullUrl } from '@/utils/tools';

  interface Props {
    src?: string; // 需要拼接
    path?: string; // 完整链接
    defaultType?: 'user' | 'video'; // 完整链接
    canPreview?: boolean; // 是否可预览
  }
  const props = defineProps<Props>();

  const showWrapper = ref<boolean>(false);
  const showPreview = () => {
    showWrapper.value = !!props?.canPreview;
  };
</script>
<template>
  <van-image
    v-bind="{ ...$attrs, src: path || (src ? getStaticFullUrl(src as string) : 'defalut.png') }"
    @click="showPreview">
    <template #loading>
      <van-loading type="spinner" size="20" />
    </template>
    <template #error>
      <div class="w-full h-full flex justify-center items-center">
        <img
          :class="defaultType === 'user' ? 'w-full' : 'w-[56%]'"
          :src="$getAssetsFile(defaultType === 'user' ? 'common/img_avatar_def.png' : 'common/logo_02.png')" />
      </div>
    </template>
  </van-image>
  <van-overlay class="wrapper flex justify-center items-center z-[999]" :show="showWrapper" @click="showWrapper = false">
    <van-image :src="path || getStaticFullUrl(src as string)">
      <template #loading>
        <van-loading type="spinner" size="20" />
      </template>
    </van-image>
  </van-overlay>
</template>

<style lang="less" scoped></style>
