<script setup lang="ts">
  import { ref, watch, defineAsyncComponent, onMounted, onBeforeMount } from 'vue';
  import { useRoute, RouteLocationNormalized } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import VConsole from '@/plugins/vconsole';
  import { setLocale } from '@/plugins/vant';
  import { storeToRefs } from '@/store';
  import { useSettingsStore } from '@/store/modules/settings';
  import { usePaymentsStore } from '@/store/modules/payments';
  import { useAccountStore } from '@/store/modules/account';
  import { useLogin } from '@/store/modules/login';
  import { LocaleList } from '@/config/region';

  const AppHeader = defineAsyncComponent(() => import('@/components/AppHeader.vue'));
  const CouponDialog = defineAsyncComponent(() => import('@/components/Dialog/coupon.vue'));
  const PaymentMethod = defineAsyncComponent(() => import('@/components/Dialog/paymentMethod.vue'));

  const route = useRoute();
  const { locale, t: $i18nt } = useI18n();
  const settingsStore = useSettingsStore();
  const { showPayment } = storeToRefs(usePaymentsStore());
  const accountStore = useAccountStore();
  const { userInfo } = storeToRefs(accountStore);
  const { token } = storeToRefs(useLogin());
  const addHeader = ref<boolean>(route?.meta?.hasHeader as boolean);
  const bgInfo: any = {
    base: 'bg-[url("@/assets/images/common/bg_app_01.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_-106px]',
    login: 'bg-[url("@/assets/images/common/bg_app_02.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_0]',
    mission: 'bg-[url("@/assets/images/common/bg_app_03.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_0]',
  };

  watch(route, (toRoute: RouteLocationNormalized) => {
    addHeader.value = !!toRoute?.meta?.hasHeader;
    setVConsole();
    changeLocation();
  });

  // vconsole手机调试工具
  const vConsole = ref<any>(null);
  const setVConsole = () => {
    if (route.query.debug && !vConsole.value) {
      vConsole.value = VConsole();
    }
  };

  // 匹配语言，只有在LocaleList里才生效
  const changeLocation = () => {
    const { meta } = route;
    const language = route.query.language && (route.query.language as string)?.toLocaleLowerCase();
    if (!language || settingsStore.LocaleInfo?.language === language) return;

    const curLocale = LocaleList?.find((item) => item.language === language);
    if (!curLocale) return;
    locale.value = curLocale.language;
    setLocale(curLocale);
    document.title = meta.i18nKey ? $i18nt(meta.i18nKey as string) : (meta.title as string);
    settingsStore.setLocale(curLocale);
  };

  const pageShow = (e: any) => {
    // 通过persisted属性判断是否存在 BF Cache
    if (e.persisted) {
      window.location.reload();
    }
  };

  onMounted(() => {
    window.addEventListener('pageshow', pageShow);
  });

  onBeforeMount(() => {
    const isFilterPages = ['/user'];
    token.value && !isFilterPages.includes(location.pathname) && accountStore.getAccount();
  });
</script>

<template>
  <suspense>
    <van-config-provider
      theme="dark"
      class="overflow-hidden text-[12px] text-white"
      :class="bgInfo[route?.meta?.bgClass as string]">
      <AppHeader v-if="addHeader && !route?.query?.hiddenNav" />
      <router-view v-slot="{ Component, route: curRouter }">
        <component
          v-if="!curRouter.meta.keepAlive"
          :is="Component"
          :key="curRouter.name"
          :class="[addHeader ? 'pt-[16px]' : '']" />
        <transition :name="(curRouter.meta.transition as string) || 'other'" mode="out-in">
          <keep-alive>
            <component
              v-if="curRouter.meta.keepAlive"
              :is="Component"
              :key="curRouter.name"
              :class="[addHeader ? 'pt-[16px]' : '']" />
          </keep-alive>
        </transition>
      </router-view>
      <CouponDialog v-if="route.name !== 'delete'" />
      <PaymentMethod v-if="userInfo" v-model="showPayment" />
    </van-config-provider>
  </suspense>
  <!-- 开启底部安全区适配 -->
  <!-- <van-number-keyboard safe-area-inset-bottom /> -->
</template>

<style scoped lang="less">
  .menuSidebar-enter-active,
  .menuSidebar-leave-active {
    transition: all 200ms ease-out;
  }

  .menuSidebar-enter-to {
    position: absolute;
    right: 0;
  }

  .menuSidebar-enter-from {
    position: absolute;
    right: -100%;
  }

  .menuSidebar-leave-to {
    position: absolute;
    left: -100%;
  }

  .menuSidebar-leave-from {
    position: absolute;
    left: 0;
  }
</style>
