import { ref } from 'vue';
import { defineStore } from 'pinia';
import { GetResponse, CoinsListResponse, EstimateGetResponse } from '@/types/integral';
import { Get, CoinsList, EstimateGet } from '@/apis/integral';
import { formatPriceFixed, formatPriceUnit } from '@/utils/format';

export const useIntegralStore = defineStore('integral', () => {
  const integralInfo = ref<GetResponse | null>();
  const coinsList = ref<CoinsListResponse['point_packs']>();
  const estimateInfo = ref<EstimateGetResponse>();

  async function getIntegral() {
    const res = await Get({});
    if (res?.code === 200) {
      const { data } = res;
      integralInfo.value = {
        ...data,
        available_point: formatPriceFixed(data.available_point),
        frozen_point: formatPriceFixed(data.frozen_point),
        balance: formatPriceFixed(data.balance),
        availablePointFormat: formatPriceUnit(data.available_point),
        frozenPointFormat: formatPriceUnit(data.frozen_point),
        balanceFormat: formatPriceUnit(data.balance),
      };
    } else {
      integralInfo.value = null;
    }
  }

  const getCoinsList = async () => {
    const res = await CoinsList({});
    if (res?.code !== 200) return;
    coinsList.value = res?.data?.point_packs;
  };

  const getEstimateInfo = async () => {
    const res = await EstimateGet({});
    if (res?.code !== 200) return;
    estimateInfo.value = res.data;
  };

  return {
    integralInfo,
    getIntegral,
    coinsList,
    getCoinsList,
    estimateInfo,
    getEstimateInfo,
  };
});
