import { LocaleIan } from '@/types';

export const Locale: Record<string, LocaleIan> = {
  // 印尼文
  id: {
    name: 'Bahasa Indonesia',
    langUI: 'id-ID',
    language: 'ind',
    region: 'id',
    timezone: 'Asia/Bangkok',
    unit: 'US $',
  },
  // 英语
  us: {
    name: 'English',
    langUI: 'en-US',
    language: 'en',
    region: 'us',
    timezone: 'America/New_York',
    unit: 'US $',
  },
  // 简体中文
  cn: {
    name: '简体中文',
    langUI: 'zh-CN',
    language: 'zh',
    region: 'cn',
    timezone: 'Asia/Shanghai',
    unit: 'US $',
  },
  // 日文
  jp: {
    name: '日本語（Nihongo）',
    langUI: 'ja-JP',
    language: 'ja',
    region: 'jp',
    timezone: 'Asia/Tokyo',
    unit: 'US $',
  },
  // 韩文
  kr: {
    name: '한국어（Hanguk-eo）',
    langUI: 'ko-KR',
    language: 'ko',
    region: 'kr',
    timezone: 'Asia/Seoul',
    unit: 'US $',
  },
};

// 语言过滤
export const localeFilter = (keys: string | string[]): LocaleIan[] => {
  if (!keys) return Object.values(Locale);
  if (typeof keys === 'string') return [Locale[keys]];
  return keys.map((key: string) => Locale[key]);
};
